@font-face {
    font-family: 'literata';
    src: url('../font/literata.ttf');

    font-family: 'opensans';
    src: url('../font/opensans.ttf');
}

.rocketship {
    width: auto !important;
    margin-left: auto;
    position: relative;
}

.rocketship:hover {
    animation: shake 0.6s;
    animation-iteration-count: infinite;
  }
  
  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

.text-container-outer {
    min-height: 10em;
}

.text-container-inner {
    border-radius: 20px;
    font-weight: 900;
    padding: 20px;
    background-color: azure;
    padding: 20px;
    border-radius: 20px;
    word-wrap: break-word;
}

.main-title {
    font-family: 'literata';
}

.sub-title {
    font-family: 'opensans';
}

.left-container {
   margin-left:20px;
}

.right-container {
    margin-right:20px !important;
}

.vtitle {
    text-decoration: underline;
    text-decoration-color: #e0ebeb;
}

@media screen and (min-width: 1024px) {
    .is-size-1-desktop {
        font-size: 70px !important;
    }

    .is-size-5-desktop {
        font-size: 30px !important;
    }
}

@media screen and (min-width: 1408px) {
    .is-size-1-desktop {
        font-size: 65px !important;
    }
}

